$(function() {
  var tv_field = $('.television-program-select2');
  tv_field.each(function(i, el){
    $(el).select2({
      placeholder: 'Įrašykite pavadinimą',
      minimumInputLength: 1,
      multiple: true,
      width: '100%',
      language: lt,
      ajax: {
        url: "/television_programs.json",
        dataType: 'JSON',
        quietMillis: 300,
        data: function (term) {
          return {
            q: { id_in_or_name_cont: term.term },
          };
        },
        processResults: function(data) {
          return { results: data.television_programs }
        },
        results: function (data) {
          return { results: data.results };
        }
      },
    });
  })

  tv_field.each(function(){
    var program_ids = $(this).data('selected');
    if (program_ids) {
      program_ids = $.grep(program_ids, function (value) {
        return value !== "" && value !== null && value !== undefined && value !== 0;
      });
    }
    if(!!program_ids){
      fetchProgramSelections($(this), "/television_programs.json", program_ids)
    }
  });

  function fetchProgramSelections(field, url, params){
    if(params.length > 0){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { id_in: params }
        }
      }).then(function (data) {
        data.television_programs.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
  
  var ownership_field = $('.television-program-single-select2');
  // var ownership_field = $(document).find('.television-program-single-select2')

  ownership_field.select2({
    placeholder: 'Įrašykite pavadinimą',
    minimumInputLength: 1,
    multiple: false,
    width: '100%',
    language: lt,
    ajax: {
      url: "/television_programs.json",
      dataType: 'JSON',
      quietMillis: 300,
      data: function (term) {
        return {
          q: { id_in_or_name_cont: term.term },
        };
      },
      processResults: function(data) {
        return { results: data.television_programs }
      },
      results: function (data) {
        return { results: data.results };
      }
    },
  });


  ownership_field.each(function(){
    var program_id = $(this).data('selected');
    if(!!program_id){
      fetchOwnershipSelections($(this), "/television_programs.json", program_id)
    }
  });

  function fetchOwnershipSelections(field, url, params){
    if(params){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { id_eq: params }
        }
      }).then(function (data) {
        data.television_programs.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
  

  $(".change_year").on("click", function(e) {
    if ($(this).find(".change-year-input").hasClass("d-none")) {
      $(this).find(".change-year-input").removeClass("d-none");
      $(this).find(".change-year-value").addClass("d-none");

      $(this).find(".change-year-input").focus();
    }
  })

  $(document).on("click", ".change_member", function(e) {
    if ($(this).find(".change-member-input").hasClass("d-none")) {
      $(this).find(".change-member-input").removeClass("d-none");
      $(this).find(".change-member-value").addClass("d-none");


      $(this).find('select').select2('open')
    }
  });

  const handleAjaxUpdate = window.debounce(function(e) {
    const input = $(this)
    const element = $(this).closest("tr");
    const name = $(this).attr("name");
    const value = $(this).val();
    const url = element.data("url");

    $.ajax({
      type: 'PATCH',
      url: url,
      data: {
        audio_visual: {[name]: value}
      },
      dataType: 'json'
    }).then(function(data) {
      input.addClass("success");
      console.log('Update successful');
    }).catch(function(jqXHR, textStatus, errorThrown) {
      input.addClass("error");
      console.error("Update failed:", jqXHR.responseJSON);
    });
  }, 500);

  $(document).on('keyup', ".change-year-input", function(e) {
    handleAjaxUpdate.call(this, e);
  });

  $(document).on('select2:select', ".change-member-input", function(e) {
    handleAjaxUpdate.call($(this).find('.inline-edit'), e);
  });

  $(document).on('blur', ".inline-edit", function(e) {
    const input = $(this)
    const value = $(this).parent().find(".change-year-value")

    input.addClass("d-none");
    input.removeClass("success");
    input.removeClass("error");
    value.html(input.val());
    value.removeClass("d-none");
  });

  $(document).on('select2:close', ".inline-edit", function(e) {
    const input = $(this).parent()
    const value = input.parent().find(".change-member-value")

    input.addClass("d-none");
    value.html($(this).select2('data')[0].text);
    value.removeClass("d-none");
  });
  
})
